import React from 'react';
import '../App.css';
import map from '../assets/worldimage.png';
import banner from '../assets/banner.png';
import banner1 from '../assets/banner1.png';
import banner2 from '../assets/banner2.png';
import banner3 from '../assets/banner3.png';
import banner4 from '../assets/banner4.png';
import brand1 from '../assets/frontbrand1.png';
import brand2 from '../assets/frontbrand2.png';
import brand3 from '../assets/frontbrand3.png';
import brand4 from '../assets/frontbrand4.png';
import brand5 from '../assets/frontbrand5.png';
import brand6 from '../assets/frontbrand6.png';
import brand7 from '../assets/frontbrand7.png';
import brand8 from '../assets/frontbrand8.png';
import brand9 from '../assets/frontbrand9.png';
import brand10 from '../assets/quarylogo57.png';
import brand11 from '../assets/quarylogo58.png';

import logo from '../assets/utllogo.png';
import { Helmet } from 'react-helmet';

import { Container, Grid, Card } from '@material-ui/core';
import withAutoplay from 'react-awesome-slider/dist/autoplay';
import 'react-awesome-slider/dist/styles.css';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.min.css';
import 'owl.carousel/dist/assets/owl.theme.default.min.css';
import AwesomeSlider from 'react-awesome-slider';
import Clock from '../widget/clock';

const AutoplaySlider = withAutoplay(AwesomeSlider);

export const LandingPage = () => (
 <div>
  <Helmet>
   <meta charSet='utf-8' />
   <title>Universal Trade & Logistics</title>
   <link rel='canonical' href='www.utl19.com' />
   <meta
    name='description'
    content=' Universal Trade and Logistics is one of the Reliable&nbsp;Power
        Technologies, Electrical/&#09; Mechanical equipment source in
        Bangladesh. Having been containing its business as an Importer, Exporter
        and one stop solution provider of electrical and electronics products.
        Universal Trade and Logistics offer one-stop, full procurement service.
        The main objectives are to get an admiring place of supplying industrial
        foreign goods in the domestic markets of spare parts & raw materials. We
        have source of lots of items from suppliers in USA, UK, Germany,
        Singapore, Japan, India,&#09; China, Turkey etc.'
   />
  </Helmet>
  <Grid container xs={11} className='slider'>
   <AutoplaySlider
    play={true}
    bullets={false}
    className='sliderbanner'
    cancelOnInteraction={false} // should stop playing on user interaction
    interval={6000}
   >
    <div data-src={banner} />
    <div data-src={banner1} />
    <div data-src={banner2} />
    <div data-src={banner3} />
    <div data-src={banner4} />
   </AutoplaySlider>
  </Grid>
  <Grid
   direction='row'
   justify='space-evenly'
   alignItems='flex-start'
   container
   xs={10}
   className='boxcontainer'
  >
   <Grid className='eachboxcotainer' container xs={3} spacing={1}>
    <Grid className='boxheader' container xs={12}>
     <h1>Company Introduction</h1>
     <Grid className='border' container xs={12}></Grid>
     <Grid className='image' container xs={12}>
      <img
       src={logo}
       style={{ top: '10px', height: '100px', width: '100%' }}
      ></img>
     </Grid>
     <Grid className='textbox' container xs={12}>
      <Container disableGutters={true}>
       <p>
        Universal Trade and Logistics is one of the Reliable&nbsp;Power
        Technologies, Electrical/&#09; Mechanical equipment source in
        Bangladesh. Having been containing its business as an Importer, Exporter
        and one stop solution provider of electrical and electronics products.
        Universal Trade and Logistics offer one-stop, full procurement service.
        The main objectives are to get an admiring place of supplying industrial
        foreign goods in the domestic markets of spare parts & raw materials. We
        have source of lots of items from suppliers in USA, UK, Germany,
        Singapore, Japan, India,&#09; China, Turkey etc.
       </p>
      </Container>
     </Grid>
    </Grid>
   </Grid>
   <Grid className='eachboxcotainer' container xs={3} spacing={1}>
    <Grid className='boxheader' container xs={12}>
     <h1>Our Mission</h1>
     <Grid className='border' container xs={12}></Grid>

     <Grid className='textbox' container xs={12}>
      <p>
       Providing the finest products, services and practices with seamless
       information flow to empower our customers, partners and communities.
      </p>
     </Grid>
    </Grid>
   </Grid>
   <Grid className='eachboxcotainer' container xs={3} spacing={1}>
    <Grid className='boxheader' container xs={12}>
     <h1>Our Clients</h1>
     <Grid className='border' container xs={12}></Grid>
     <Grid className='image' container xs={12}>
      <img
       src={logo}
       style={{ top: '10px', height: '100px', width: '100%' }}
      ></img>
     </Grid>
     <Grid className='textbox' container xs={12}>
      <p>
       We are now working as an enlisted supplier with renowned Steel Industry,
       Fertilizer Company, Pharmaceuticals Industries and RMG Factories.
      </p>
     </Grid>
    </Grid>
   </Grid>
  </Grid>

  <Grid container xs={10} className='brandtile'>
   <Card className='brandcard'>
    <OwlCarousel autoPlay nav items='6' autoplay loop={true} margin={10}>
     <div className='brand'>
      <img src={brand1} style={{ height: '100px', width: '140px' }} />
     </div>
     <div className='brand'>
      <img src={brand2} style={{ height: '100px', width: '140px' }} />
     </div>
     <div className='brand'>
      <img src={brand3} style={{ height: '100px', width: '140px' }} />
     </div>
     <div className='brand'>
      <img src={brand4} style={{ height: '100px', width: '140px' }} />
     </div>
     <div className='brand'>
      <img src={brand5} style={{ height: '100px', width: '140px' }} />
     </div>
     <div className='brand'>
      <img src={brand6} style={{ height: '100px', width: '140px' }} />
     </div>
     <div className='brand'>
      <img src={brand7} style={{ height: '100px', width: '140px' }} />
     </div>
     <div className='brand'>
      <img src={brand8} style={{ height: '100px', width: '140px' }} />
     </div>
     <div className='brand'>
      <img src={brand9} style={{ height: '100px', width: '140px' }} />
     </div>
     <div className='brand'>
      <img src={brand10} style={{ height: '100px', width: '140px' }} />
     </div>
     <div className='brand'>
      <img src={brand11} style={{ height: '100px', width: '140px' }} />
     </div>
    </OwlCarousel>
   </Card>
  </Grid>
  <Grid
   direction='row'
   justify='space-between'
   container
   xs={9}
   justify='flex-end'
   alignItems='flex-end'
   className='datesection'
  >
   <Clock></Clock>
  </Grid>

  <Grid
   direction='row'
   justify='space-between'
   container
   xs={9}
   className='mapsection'
  >
   <Grid container xs={6}>
    <img
     src={map}
     style={{ top: '10px', height: '200px', width: '100%' }}
    ></img>
   </Grid>
   <Grid container xs={4} justify='center' className='addressbox'>
    <Container>
     <h3>Overseas Office</h3>
     <h2>Germany:</h2>
     <p>Walther-Rathenau-Straße 55 Zimmer nummer: 6302, 39104, Magdeburg</p>
     <p>Phone# +4917667528478</p>
     <p>Email: sales@utl19.com</p>
     <br />
     <h2>China: </h2>
     <p>
      Building No. 105, 1st Floor, Road No. 103, Bantian Liyuan New Town,
      Longgang, Shenzen City,Guangdong, China
     </p>
     <p>Phone# +8615622193129</p>
     <p>Email: universal19@163.com</p>
    </Container>
   </Grid>
  </Grid>
 </div>
);
