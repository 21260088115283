import React from 'react';
import { Grid, Card } from '@material-ui/core';

export const About = () => {
 return (
  <Grid
   direction='row'
   justify='space-between'
   alignItems='center'
   container
   xs={11}
   className='aboutcontainer'
  >
   <Grid
    alignItems='flex-start'
    container
    xs={10}
    className='aboutdetailscontainer'
   >
    <Card className='aboutdetailscard'>
     <h2>Company Introduction</h2>
     <hr />
     <p>
      Universal Trade and Logistics is one of the Reliable
      Power,Technologies,Electrical/ Mechanical equipment source in
      Bangladesh,having been containing its business as an Importer,Exporter and
      one stop solution provider of electrical and electronics
      products.Universal Trade and Logistics offer one-stop,full procurement
      service.The main objectives are to get an admiring place of supplying
      industrial foreign goods in the domestics markets on spare parts & raw
      materials. We have source of lots of line items from suppliers in
      USA,UK,Germany,Singapore,Japan,India, China, Turkey etc.
     </p>
    </Card>
   </Grid>
   <Grid
    alignItems='flex-start'
    container
    xs={10}
    className='aboutdetailscontainer'
   >
    <Card className='aboutdetailscard1'>
     <h2>Our Mission</h2>
     <hr />
     <p>
      Mission Providing the finest products, services and practices with
      seamless information flow to empower our customsrs,partners and
      communities.
     </p>
    </Card>
   </Grid>
   <Grid
    alignItems='flex-start'
    container
    xs={10}
    className='aboutdetailscontainer'
   >
    <Card className='aboutdetailscard2'>
     <h2>Our Clients</h2>
     <hr />
     <p>
      We are now working as an enlisted supplier with renowned Steel company,
      Fertilizer Company, Pharmaceuticals Industries and RMG Factories.
     </p>
    </Card>
   </Grid>
   <Grid
    alignItems='flex-start'
    container
    xs={12}
    direction='row'
    className='aboutsectioncontect'
   >
    <Grid
     alignItems='flex-start'
     container
     xs={3}
     className='aboutdetailscontainer'
    >
     <Card className='aboutsectionaddessbox'>
      <h4>Bangladesh Office:</h4>
      <hr />
      <p>234, Singapore Market, Access Road,Hajipara, Agrabad,Chittagong.</p>
      <p>Phone:+8801838669066</p>
      <p>+8801571736808</p>
      <p> Email: sales.utl19@gmail.com</p>
     </Card>
    </Grid>
    <Grid
     alignItems='flex-start'
     container
     xs={3}
     className='aboutdetailscontainer'
    >
     <Card className='aboutsectionaddessbox'>
      <h4>Germany Office:</h4>
      <hr />
      <p>
       Walter ratheneu strass 56, Room number: 2403,39104 Magdeburg, Germany.
      </p>
      <p>Phone# +4915201334252</p>
      <p>Email: arafatshimul@gmail.com</p>
     </Card>
    </Grid>
    <Grid
     alignItems='flex-start'
     container
     xs={3}
     className='aboutdetailscontainer'
    >
     <Card className='aboutsectionaddessbox'>
      <h4>China Office:</h4>
      <hr />
      <p>
       Building No. 105, 1st Floor, Road No. 103, Bantian Liyuan New Town,
       Longgang, Shenzen City,Guangdong, China
      </p>
      <p>Phone# +8615622193129</p>
      <p>Email: universal19@163.com</p>
     </Card>
    </Grid>
   </Grid>
  </Grid>
 );
};
