import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { LandingPage } from './pages/landingpage';
import { Projects } from './pages/projects';
import { Products } from './pages/products';
import { AirShipment } from './pages/airshipment';
import { SeaShipment } from './pages/seashipment';
import { Contact } from './pages/contact';
import { Logistics } from './pages/logistics';
import { Brand } from './pages/brand';
import { About } from './pages/about';
import { Quary } from './pages/quary';
import { NoMatch } from './pages/nomatch';
import { Layout } from './pages/layout';
import Header from './widget/header';
import { Footer } from './widget/footer';

class App extends Component {
 render() {
  return (
   <React.Fragment>
    <Router>
     <Header />
     <Layout>
      <Switch>
       <Route exact path='/' component={LandingPage} />
       <Route path='/products' component={Products} />
       <Route path='/brand' component={Brand} />

       <Route path='/projects' component={Projects} />
       <Route path='/contact' component={Contact} />
       <Route path='/airshipment' component={AirShipment} />
       <Route path='/seashipment' component={SeaShipment} />

       <Route path='/logistics' component={Logistics} />
       <Route path='/about' component={About} />
       <Route path='/query' component={Quary} />
       <Route component={NoMatch} />
      </Switch>
     </Layout>
     <Footer />
    </Router>
   </React.Fragment>
  );
 }
}

export default App;
