import React from 'react';

import { Card, Grid } from '@material-ui/core';

import { Loading } from './loader';

const ProjectsLoading = ({ projects, loading }) => {
 if (loading) {
  return (
   <div>
    <Loading />
   </div>
  );
 }

 return (
  <Grid container xs={12} justify='flex-start' direction='column'>
   <Grid className='projectshead'>
    <h2>Our Projects</h2>
   </Grid>
   {projects.map((tile) => (
    <Card className='projectlist'>
     <Grid container xs={12} direction='column'>
      <Grid container xs={10} justify='flex-start' direction='row'>
       <Grid direction='column'>
        <h1>{tile.month}</h1>
        <h2>{tile.day}</h2>
        <h1>{tile.year}</h1>
       </Grid>
       <Grid container xs={8} className='shortdescription'>
        <h6>{tile.deliveryLocation}</h6>
       </Grid>
      </Grid>
      <Grid container xs={12}>
       <Card className='photoproject'>
        <img
         style={{
          margin: 'auto',
          width: ' 100%',
          height: '100%',
          resize: 'block',
          backgroundSize: 'cover',
         }}
         src={tile.productPhotoLocation.replace('/', '')}
        />
       </Card>
      </Grid>
      <Grid container xs={4} className='projectlast' direction='column'>
       <p>{tile.productName}</p>
       <p>{tile.productModel}</p>
       <p>{tile.productBrand}</p>
       <p>{tile.productOrigin}</p>
      </Grid>
     </Grid>
    </Card>
   ))}
  </Grid>
 );
};

export default ProjectsLoading;
