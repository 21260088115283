import React from 'react';
import '../App.css';
import logo from '../assets/utllogo.png';
import facebook from '../assets/facebook.png';
import whatsapp from '../assets/whatsapp.png';
import skype from '../assets/skype.png';
import linkdin from '../assets/linkdin.png';

import history from '../widget/history';

import { Container, Grid, Button, Card } from '@material-ui/core';
import { Link } from 'react-router-dom';

function openInNewTab(url) {
 var win = window.open(url, '_blank');
 win.focus();
}

const Header = () => {
 return (
  <Grid
   container
   spacing={0}
   direction='column'
   alignItems='center'
   justify='flex-start'
  >
   <Grid
    container
    xs={11}
    direction='row'
    spacing={0}
    justify='center'
    alignItems='center'
    className='header'
   >
    <Grid container xs={6} justify='flex-start'>
     <img src={logo} style={{ height: '100px', width: '300px' }}></img>
    </Grid>
    <Grid container xs={4} justify='center' className='contactbox'>
     <Card className='contactboxcard'>
      <h2>Bangladesh Office:</h2>
      <p>234, Singapore Market, Access Road, Hajipara, Agrabad, Chittagong.</p>
      <p>Phone:+88031726265,+8801571736808 </p>
      <p>Email: sales.utl19@gmail.com</p>
      <Grid
       container
       xs={8}
       className='sociallogo'
       direction='row'
       justify='space-between'
       alignItems='center'
      >
       <img
        src={facebook}
        style={{ height: '28px', width: '30px' }}
        onClick={() => {
         openInNewTab('https://www.facebook.com/utl19');
        }}
       ></img>
       <img
        src={whatsapp}
        style={{ height: '28px', width: '30px' }}
        onClick={() => {
         openInNewTab('https://wa.me/8801670391950');
        }}
       ></img>
       <img
        src={skype}
        style={{ height: '30px', width: '30px' }}
        onClick={() => {
         openInNewTab('https://join.skype.com/invite/UwfChDpUmU0A');
        }}
       ></img>
       <img
        src={linkdin}
        style={{ height: '30px', width: '30px' }}
        onClick={() => {
         openInNewTab('https://www.linkedin.com/company/utl19');
        }}
       ></img>
      </Grid>
     </Card>
    </Grid>
   </Grid>
   <Grid
    className='headerbutton'
    justify='center'
    alignItems='center'
    container
    direction='row'
    xs={12}
   >
    <Link to='./'>
     <button
      style={{
       height: '40px',
       width: '160px',
       margin: '5px',
       color: 'white',
       borderRadius: '10px',
       outline: 'none ',

       boxShadow: 'none',
       backgroundColor: '#2E86C1',
       textDecorationLine: 'none',
      }}
      color='secondary'
     >
      Home
     </button>
    </Link>

    <Link to='/products'>
     <button
      style={{
       height: '40px',
       width: '160px',
       margin: '5px',
       color: 'white',
       borderRadius: '10px',
       outline: 'none ',

       boxShadow: 'none',
       backgroundColor: '#2E86C1',
       textDecorationLine: 'none',
      }}
      color='secondary'
     >
      Products
     </button>
    </Link>

    <Link to='/brand'>
     <button
      style={{
       height: '40px',
       width: '160px',
       margin: '5px',
       color: 'white',
       borderRadius: '10px',
       outline: 'none ',

       boxShadow: 'none',
       backgroundColor: '#2E86C1',
       textDecorationLine: 'none',
      }}
      color='secondary'
     >
      Brand
     </button>
    </Link>

    <Link to='/projects'>
     <button
      style={{
       height: '40px',
       width: '160px',
       margin: '5px',
       color: 'white',
       borderRadius: '10px',
       outline: 'none ',

       boxShadow: 'none',
       backgroundColor: '#2E86C1',
       textDecorationLine: 'none',
      }}
      color='secondary'
     >
      Projects
     </button>
    </Link>
    <Link to='/logistics'>
     <button
      style={{
       height: '40px',
       width: '160px',
       margin: '5px',
       color: 'white',
       borderRadius: '10px',
       outline: 'none ',

       boxShadow: 'none',
       backgroundColor: '#2E86C1',
       textDecorationLine: 'none',
      }}
      color='secondary'
     >
      Logistics
     </button>
    </Link>
    <Link to='/contact'>
     <button
      style={{
       height: '40px',
       width: '160px',
       margin: '5px',
       color: 'white',
       borderRadius: '10px',
       outline: 'none ',

       boxShadow: 'none',
       backgroundColor: '#2E86C1',
       textDecorationLine: 'none',
      }}
      color='secondary'
     >
      Contact Us
     </button>
    </Link>
   </Grid>
  </Grid>
 );
};

export default Header;
