import { Grid } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import AirLoading from '../widget/airloading';
import Pagination from '../widget/pagination';

import axios from 'axios';

export const AirShipment = () => {
 const [projects, setprojects] = useState([]);
 const [loading, setLoading] = useState(false);
 const [currentPage, setCurrentPage] = useState(1);
 const [postsPerPage] = useState(4);
 useEffect(() => {
  const fetchprojects = async () => {
   setLoading(true);
   const res = await axios.get(
    'https://utl-backend.herokuapp.com/getairshipment'
   );
   setprojects(res.data);
   setLoading(false);
  };
  fetchprojects();
 }, []);
 // Get current posts
 const indexOfLastPost = currentPage * postsPerPage;
 const indexOfFirstPost = indexOfLastPost - postsPerPage;
 const currentprojects = projects.slice(indexOfFirstPost, indexOfLastPost);

 // Change page
 const paginate = (pageNumber) => setCurrentPage(pageNumber);
 return (
  <Grid container xs={12} className='projectlayer'>
   <Grid container xs={12} justify='flex-start' direction='row'>
    <AirLoading airshipment={currentprojects} loading={loading} sp />
    <Grid>
     <Pagination
      postsPerPage={postsPerPage}
      totalPosts={projects.length}
      paginate={paginate}
     />
    </Grid>
   </Grid>
  </Grid>
 );
};
