import React from 'react';
import { Grid, Card, Button } from '@material-ui/core';
import { Link } from 'react-router-dom';

export const Logistics = () => {
 return (
  <Grid
   direction='row'
   justify='space-between'
   alignItems='center'
   container
   xs={9}
   className='aboutcontainer'
  >
   <Grid
    alignItems='flex-start'
    container
    xs={10}
    className='aboutdetailscontainer'
   >
    <Card className='logisticsdetailscard'>
     <h3>Our Services</h3>
     <hr />
     <h1>
      We Universal Trade & Logistics Also Provide Door To Door Product Delivery
      Service From Country Like China, India, Germany, Turkey, Hong Kong, USA To
      Bangladesh.
     </h1>
     <Grid container direction='row' className='sublinelogistics'>
      <h6>&raquo;</h6>
      <h2>Door to Door Shipment (by air & by sea)</h2>
     </Grid>
     <Grid container direction='row' className='sublinelogistics1'>
      <h6>&raquo;</h6>
      <h2>Warehouse Facility</h2>
     </Grid>

     <Grid container direction='row' className='sublinelogistics2'>
      <h6>&raquo;</h6>
      <h2>Receive LC/ TT & LCL/ FCL Shipment Support</h2>
     </Grid>
     <Grid container direction='row' className='sublinelogistics3'>
      <h6>&raquo;</h6>
      <h2>Product Sourching, Import-Export & Trading Support</h2>
     </Grid>
     <Grid container direction='row' className='sublinelogistics4'>
      <h6>&raquo;</h6>
      <h2>Customs Clearance Support</h2>
     </Grid>
     <Grid container direction='row' className='sublinelogistics5'>
      <h6>&raquo;</h6>
      <h2>Interpreter Support</h2>
     </Grid>
     <Grid container direction='row' className='sublinelogistics6'>
      <h6>&raquo;</h6>
      <h2>Cash On Delivery Sevices (COD)</h2>
     </Grid>
     <Grid container direction='row' className='sublinelogistics7'>
      <h6>&raquo;</h6>
      <h2>Invitation Letter/ Visa Support</h2>
     </Grid>
    </Card>
   </Grid>
   <Grid
    alignItems='flex-start'
    container
    xs={10}
    className='aboutdetailscontainer'
   >
    <Card className='logisticsdetailscard1'>
     <h2>Air Shipment</h2>
     <hr />
     <Link to='/airshipment'>
      <button
       style={{
        height: '40px',
        width: '160px',
        margin: '5px',
        color: 'white',
        borderRadius: '10px',
        outline: 'none ',

        boxShadow: 'none',
        backgroundColor: '#2E86C1',
        textDecorationLine: 'none',
       }}
       color='secondary'
      >
       CHECK OUT
      </button>
     </Link>
    </Card>
   </Grid>
   <Grid
    alignItems='flex-start'
    container
    xs={10}
    className='aboutdetailscontainer'
   >
    <Card className='logisticsdetailscard2'>
     <h2>Sea Shipment</h2>
     <hr />
     <Link to='/seashipment'>
      <button
       style={{
        height: '40px',
        width: '160px',
        margin: '5px',
        color: 'white',
        borderRadius: '10px',
        outline: 'none ',

        boxShadow: 'none',
        backgroundColor: '#2E86C1',
        textDecorationLine: 'none',
       }}
       color='secondary'
      >
       CHECK OUT
      </button>
     </Link>
    </Card>
   </Grid>
  </Grid>
 );
};
