import React, { useState } from 'react';
import { Grid, Container, TextField, Button } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { connect, useDispatch } from 'react-redux';
import { fetchProductId } from '../action/index';
import brand59 from '../assets/frontbrand7.png';
import brand60 from '../assets/quarylogo65.png';
import brand61 from '../assets/quarylogo64.png';
import brand62 from '../assets/quarylogo57.png';

import brand1 from '../assets/quarylogo1.png';
import brand2 from '../assets/quarylogo2.png';
import brand3 from '../assets/quarylogo3.png';
import brand4 from '../assets/quarylogo4.png';
import brand5 from '../assets/quarylogo5.png';
import brand6 from '../assets/quarylogo6.png';
import brand7 from '../assets/quarylogo7.png';
import brand8 from '../assets/quarylogo8.png';
import brand9 from '../assets/quarylogo9.png';
import brand10 from '../assets/quarylogo10.png';
import brand11 from '../assets/quarylogo11.png';
import brand12 from '../assets/quarylogo12.png';
import brand13 from '../assets/quarylogo13.png';
import brand14 from '../assets/quarylogo14.png';
import brand15 from '../assets/quarylogo15.png';
import brand16 from '../assets/quarylogo16.png';
import brand17 from '../assets/quarylogo17.png';
import brand18 from '../assets/quarylogo18.png';
import brand19 from '../assets/quarylogo19.png';
import brand20 from '../assets/quarylogo20.png';
import brand21 from '../assets/quarylogo21.png';
import brand22 from '../assets/quarylogo22.png';
import brand23 from '../assets/quarylogo23.png';
import brand24 from '../assets/quarylogo24.png';
import brand25 from '../assets/quarylogo25.png';
import brand26 from '../assets/quarylogo26.png';
import brand27 from '../assets/quarylogo27.png';
import brand28 from '../assets/quarylogo28.png';
import brand29 from '../assets/quarylogo29.png';
import brand30 from '../assets/quarylogo30.png';
import brand31 from '../assets/quarylogo31.png';
import brand32 from '../assets/quarylogo32.png';
import brand33 from '../assets/quarylogo33.png';
import brand34 from '../assets/quarylogo34.png';
import brand35 from '../assets/quarylogo35.png';
import brand36 from '../assets/quarylogo36.png';
import brand37 from '../assets/quarylogo37.png';
import brand38 from '../assets/quarylogo38.png';
import brand39 from '../assets/quarylogo39.png';
import brand40 from '../assets/quarylogo40.png';
import brand41 from '../assets/quarylogo41.png';
import brand42 from '../assets/quarylogo42.png';
import brand43 from '../assets/quarylogo43.png';
import brand44 from '../assets/quarylogo44.png';
import brand45 from '../assets/quarylogo45.png';
import brand46 from '../assets/quarylogo46.png';
import brand47 from '../assets/quarylogo47.png';
import brand48 from '../assets/quarylogo48.png';
import brand49 from '../assets/quarylogo49.png';
import brand50 from '../assets/quarylogo50.png';
import brand51 from '../assets/quarylogo51.png';
import brand52 from '../assets/quarylogo52.png';
import brand53 from '../assets/quarylogo53.png';
import brand54 from '../assets/quarylogo54.png';
import brand55 from '../assets/quarylogo55.png';
import * as emailjs from 'emailjs-com';
import history from '../widget/history';

export const Brand = () => {
 const dispatch = useDispatch();
 const [state, setState] = useState({
  companyname: '',
  companyemail: '',
  phone: '',
  message: '',
 });

 function handleChange(evt) {
  const value = evt.target.value;
  setState({
   ...state,
   [evt.target.name]: value,
  });
 }
 function query() {
  state.companyname === '' ||
  state.companyname === '' ||
  state.phone === '' ||
  state.message === ''
   ? window.confirm('please fillup all requirement field')
   : emailjs
      .send(
       'service_y3ckqsh',
       'template_t7uqypk',
       {
        message: state.message,
        company_name: state.companyname,
        company_phone: state.phone,
        company_email: state.companyemail,
       },
       'user_IdQoGXFzJTwNYRXZGw8aI'
      )
      .then(
       function (response) {
        if (
         window.confirm(
          'Your message is successfully send.We will conatact you within 24 hours..'
         )
        ) {
         history.push('./');
        }
       },
       function (error) {
        console.log('FAILED...', error);
       }
      );
 }

 return (
  <Grid
   container
   xs={12}
   direction='row'
   justify='space-around'
   alignItems='flex-start'
   className='brandquarybox'
  >
   <Grid
    container
    xs={5}
    direction='column'
    justify='flex-start'
    alignItems='flex-start'
    className='brandbox'
   >
    <Grid
     container
     xs={12}
     direction='row'
     justify='space-between'
     alignItems='flex-start'
    >
     <Link to='/query'>
      <Grid justify='space-between' container xs={3}>
       <img
        src={brand59}
        style={{ height: '60px', width: '90px' }}
        onClick={() => dispatch(fetchProductId('AUMA'))}
       />
      </Grid>
     </Link>
     <Link to='/query'>
      <Grid justify='space-between' container xs={3}>
       <img
        src={brand60}
        style={{ height: '60px', width: '90px' }}
        onClick={() => dispatch(fetchProductId('REXROTH'))}
       />
      </Grid>
     </Link>
     <Link to='/query'>
      <Grid justify='space-between' container xs={3}>
       <img
        src={brand61}
        style={{ height: '60px', width: '90px' }}
        onClick={() => dispatch(fetchProductId('GENERAL ELECTIC'))}
       />
      </Grid>
     </Link>
     <Link to='/query'>
      <Grid justify='space-between' container xs={3}>
       <img
        src={brand62}
        style={{ height: '60px', width: '90px' }}
        onClick={() => dispatch(fetchProductId('SEMIKRON'))}
       />
      </Grid>
     </Link>
    </Grid>
    <Grid
     container
     xs={12}
     direction='row'
     justify='space-between'
     alignItems='flex-start'
    >
     <Link to='/query'>
      <Grid justify='space-between' container xs={3}>
       <img
        src={brand5}
        style={{ height: '60px', width: '90px' }}
        onClick={() => dispatch(fetchProductId('SIEMENS'))}
       />
      </Grid>
     </Link>
     <Link to='/query'>
      <Grid justify='space-between' container xs={3}>
       <img
        src={brand11}
        style={{ height: '60px', width: '90px' }}
        onClick={() => dispatch(fetchProductId('ABB'))}
       />
      </Grid>
     </Link>
     <Link to='/query'>
      <Grid justify='space-between' container xs={3}>
       <img
        src={brand3}
        style={{ height: '60px', width: '90px' }}
        onClick={() => dispatch(fetchProductId('PEPPERL+FUCHS'))}
       />
      </Grid>
     </Link>
     <Link to='/query'>
      <Grid justify='space-between' container xs={3}>
       <img
        src={brand8}
        style={{ height: '60px', width: '90px' }}
        onClick={() => dispatch(fetchProductId('WENGLOR'))}
       />
      </Grid>
     </Link>
    </Grid>
    <Grid
     container
     xs={12}
     direction='row'
     justify='space-between'
     alignItems='flex-start'
    >
     <Link to='/query'>
      <Grid justify='space-between' container xs={3}>
       <img
        src={brand17}
        style={{ height: '60px', width: '90px' }}
        onClick={() => dispatch(fetchProductId('IFM'))}
       />
      </Grid>
     </Link>

     <Link to='/query'>
      <Grid justify='space-between' container xs={3}>
       <img
        src={brand20}
        style={{ height: '60px', width: '90px' }}
        onClick={() => dispatch(fetchProductId('BALLUFF'))}
       />
      </Grid>
     </Link>
     <Link to='/query'>
      <Grid justify='space-between' container xs={3}>
       <img
        src={brand26}
        style={{ height: '60px', width: '90px' }}
        onClick={() => dispatch(fetchProductId('ASCO NUMATICS'))}
       />
      </Grid>
     </Link>
     <Link to='/query'>
      <Grid justify='space-between' container xs={3}>
       <img
        src={brand38}
        style={{ height: '60px', width: '90px' }}
        onClick={() => dispatch(fetchProductId('OMRON'))}
       />
      </Grid>
     </Link>
    </Grid>

    <Grid
     container
     xs={12}
     direction='row'
     justify='space-between'
     alignItems='flex-start'
    >
     <Link to='/query'>
      <Grid justify='space-between' container xs={3}>
       <img
        src={brand40}
        style={{ height: '60px', width: '90px' }}
        onClick={() => dispatch(fetchProductId('ROSEMOUNT'))}
       />
      </Grid>
     </Link>
     <Link to='/query'>
      <Grid justify='space-between' container xs={3}>
       <img
        src={brand43}
        style={{ height: '60px', width: '90px' }}
        onClick={() => dispatch(fetchProductId('EMERSON'))}
       />
      </Grid>
     </Link>

     <Link to='/query'>
      <Grid justify='space-between' container xs={3}>
       <img
        src={brand45}
        style={{ height: '60px', width: '90px' }}
        onClick={() => dispatch(fetchProductId('FESTO'))}
       />
      </Grid>
     </Link>
     <Link to='/query'>
      <Grid justify='space-between' container xs={3}>
       <img
        src={brand44}
        style={{ height: '60px', width: '90px' }}
        onClick={() => dispatch(fetchProductId('ENDRESS+HAUSER'))}
       />
      </Grid>
     </Link>
    </Grid>

    <Grid
     container
     xs={12}
     direction='row'
     justify='space-between'
     alignItems='flex-start'
    >
     <Link to='/query'>
      <Grid justify='space-between' container xs={3}>
       <img
        src={brand49}
        style={{ height: '60px', width: '90px' }}
        onClick={() => dispatch(fetchProductId('HONEYWELL'))}
       />
      </Grid>
     </Link>
     <Link to='/query'>
      <Grid justify='space-between' container xs={3}>
       <img
        src={brand50}
        style={{ height: '60px', width: '90px' }}
        onClick={() => dispatch(fetchProductId('HYDAC'))}
       />
      </Grid>
     </Link>
     <Link to='/query'>
      <Grid justify='space-between' container xs={3}>
       <img
        src={brand48}
        style={{ height: '60px', width: '90px' }}
        onClick={() => dispatch(fetchProductId('HEIDENHAIN'))}
       />
      </Grid>
     </Link>
     <Link to='/query'>
      <Grid justify='space-between' container xs={3}>
       <img
        src={brand32}
        style={{ height: '60px', width: '90px' }}
        onClick={() => dispatch(fetchProductId('SCHNEIDER'))}
       />
      </Grid>
     </Link>
    </Grid>
    <Grid
     container
     xs={12}
     direction='row'
     justify='space-between'
     alignItems='flex-start'
    >
     <Link to='/query'>
      <Grid justify='space-between' container xs={3}>
       <img
        src={brand13}
        style={{ height: '60px', width: '90px' }}
        onClick={() => dispatch(fetchProductId('BAUMER'))}
       />
      </Grid>
     </Link>
     <Link to='/query'>
      <Grid justify='space-between' container xs={3}>
       <img
        src={brand7}
        style={{ height: '60px', width: '90px' }}
        onClick={() => dispatch(fetchProductId('WESTROCK'))}
       />
      </Grid>
     </Link>

     <Link to='/query'>
      <Grid justify='space-between' container xs={3}>
       <img
        src={brand1}
        style={{ height: '60px', width: '90px' }}
        onClick={() => dispatch(fetchProductId('WIKAI'))}
       />
      </Grid>
     </Link>
     <Link to='/query'>
      <Grid justify='space-between' container xs={3}>
       <img
        src={brand2}
        style={{ height: '60px', width: '90px' }}
        onClick={() => dispatch(fetchProductId('SICK'))}
       />
      </Grid>
     </Link>
    </Grid>
    <Grid
     container
     xs={12}
     direction='row'
     justify='space-between'
     alignItems='flex-start'
    >
     <Link to='/query'>
      <Grid justify='space-between' container xs={3}>
       <img
        src={brand21}
        style={{ height: '60px', width: '90px' }}
        onClick={() => dispatch(fetchProductId('WADECO'))}
       />
      </Grid>
     </Link>
     <Link to='/query'>
      <Grid justify='space-between' container xs={3}>
       <img
        src={brand22}
        style={{ height: '60px', width: '90px' }}
        onClick={() => dispatch(fetchProductId('TURCK'))}
       />
      </Grid>
     </Link>
     <Link to='/query'>
      <Grid justify='space-between' container xs={3}>
       <img
        src={brand23}
        style={{ height: '60px', width: '90px' }}
        onClick={() => dispatch(fetchProductId('CALEX'))}
       />
      </Grid>
     </Link>
     <Link to='/query'>
      <Grid justify='space-between' container xs={3}>
       <img
        src={brand24}
        style={{ height: '60px', width: '90px' }}
        onClick={() => dispatch(fetchProductId('ROENHELD'))}
       />
      </Grid>
     </Link>
    </Grid>
    <Grid
     container
     xs={12}
     direction='row'
     justify='space-between'
     alignItems='flex-start'
    >
     <Link to='/query'>
      <Grid justify='space-between' container xs={3}>
       <img
        src={brand25}
        style={{ height: '60px', width: '90px' }}
        onClick={() => dispatch(fetchProductId('FOXBORO ECKARDT'))}
       />
      </Grid>
     </Link>
     <Link to='/query'>
      <Grid justify='space-between' container xs={3}>
       <img
        src={brand55}
        style={{ height: '60px', width: '90px' }}
        onClick={() => dispatch(fetchProductId('KIRCHNER'))}
       />
      </Grid>
     </Link>

     <Link to='/query'>
      <Grid justify='space-between' container xs={3}>
       <img
        src={brand27}
        style={{ height: '60px', width: '90px' }}
        onClick={() => dispatch(fetchProductId('HACH'))}
       />
      </Grid>
     </Link>
     <Link to='/query'>
      <Grid justify='space-between' container xs={3}>
       <img
        src={brand28}
        style={{ height: '60px', width: '90px' }}
        onClick={() => dispatch(fetchProductId('FLOWLINE'))}
       />
      </Grid>
     </Link>
    </Grid>
    <Grid
     container
     xs={12}
     direction='row'
     justify='space-between'
     alignItems='flex-start'
    >
     <Link to='/query'>
      <Grid justify='space-between' container xs={3}>
       <img
        src={brand29}
        style={{ height: '60px', width: '90px' }}
        onClick={() => dispatch(fetchProductId('ASHCROFT'))}
       />
      </Grid>
     </Link>
     <Link to='/query'>
      <Grid justify='space-between' container xs={3}>
       <img
        src={brand30}
        style={{ height: '60px', width: '90px' }}
        onClick={() => dispatch(fetchProductId('SEAMETRICS'))}
       />
      </Grid>
     </Link>
     <Link to='/query'>
      <Grid justify='space-between' container xs={3}>
       <img
        src={brand47}
        style={{ height: '60px', width: '90px' }}
        onClick={() => dispatch(fetchProductId('GOSSEN METRAWATT'))}
       />
      </Grid>
     </Link>
     <Link to='/query'>
      <Grid justify='space-between' container xs={3}>
       <img
        src={brand31}
        style={{ height: '60px', width: '90px' }}
        onClick={() => dispatch(fetchProductId('ELOBAU'))}
       />
      </Grid>
     </Link>
    </Grid>
    <Grid
     container
     xs={12}
     direction='row'
     justify='space-between'
     alignItems='flex-start'
    >
     <Link to='/query'>
      <Grid justify='space-between' container xs={3}>
       <img
        src={brand33}
        style={{ height: '60px', width: '90px' }}
        onClick={() => dispatch(fetchProductId('HENGSTLER'))}
       />
      </Grid>
     </Link>
     <Link to='/query'>
      <Grid justify='space-between' container xs={3}>
       <img
        src={brand34}
        style={{ height: '60px', width: '90px' }}
        onClick={() => dispatch(fetchProductId('HONSBERG'))}
       />
      </Grid>
     </Link>
     <Link to='/query'>
      <Grid justify='space-between' container xs={3}>
       <img
        src={brand35}
        style={{ height: '60px', width: '90px' }}
        onClick={() => dispatch(fetchProductId('INOR'))}
       />
      </Grid>
     </Link>
     <Link to='/query'>
      <Grid justify='space-between' container xs={3}>
       <img
        src={brand36}
        style={{ height: '60px', width: '90px' }}
        onClick={() => dispatch(fetchProductId('JUMO'))}
       />
      </Grid>
     </Link>
    </Grid>
    <Grid
     container
     xs={12}
     direction='row'
     justify='space-between'
     alignItems='flex-start'
    >
     <Link to='/query'>
      <Grid justify='space-between' container xs={3}>
       <img
        src={brand37}
        style={{ height: '60px', width: '90px' }}
        onClick={() => dispatch(fetchProductId('THERMO'))}
       />
      </Grid>
     </Link>

     <Link to='/query'>
      <Grid justify='space-between' container xs={3}>
       <img
        src={brand39}
        style={{ height: '60px', width: '90px' }}
        onClick={() => dispatch(fetchProductId('TELCO'))}
       />
      </Grid>
     </Link>
     <Link to='/query'>
      <Grid justify='space-between' container xs={3}>
       <img
        src={brand16}
        style={{ height: '60px', width: '90px' }}
        onClick={() => dispatch(fetchProductId('DOLD'))}
       />
      </Grid>
     </Link>

     <Link to='/query'>
      <Grid justify='space-between' container xs={3}>
       <img
        src={brand42}
        style={{ height: '60px', width: '90px' }}
        onClick={() => dispatch(fetchProductId('GEFRAN'))}
       />
      </Grid>
     </Link>
    </Grid>
    <Grid
     container
     xs={12}
     direction='row'
     justify='space-between'
     alignItems='flex-start'
    >
     <Link to='/query'>
      <Grid justify='space-between' container xs={3}>
       <img
        src={brand41}
        style={{ height: '60px', width: '90px' }}
        onClick={() => dispatch(fetchProductId('GEFRAN'))}
       />
      </Grid>
     </Link>
     <Link to='/query'>
      <Grid justify='space-between' container xs={3}>
       <img
        src={brand12}
        style={{ height: '60px', width: '90px' }}
        onClick={() => dispatch(fetchProductId('BARKSDALE'))}
       />
      </Grid>
     </Link>
     <Link to='/query'>
      <Grid justify='space-between' container xs={3}>
       <img
        src={brand14}
        style={{ height: '60px', width: '90px' }}
        onClick={() => dispatch(fetchProductId('CONTROL AIR'))}
       />
      </Grid>
     </Link>

     <Link to='/query'>
      <Grid justify='space-between' container xs={3}>
       <img
        src={brand15}
        style={{ height: '60px', width: '90px' }}
        onClick={() => dispatch(fetchProductId('GEMU'))}
       />
      </Grid>
     </Link>
    </Grid>
    <Grid
     container
     xs={12}
     direction='row'
     justify='space-between'
     alignItems='flex-start'
    >
     <Link to='/query'>
      <Grid justify='space-between' container xs={3}>
       <img
        src={brand45}
        style={{ height: '60px', width: '90px' }}
        onClick={() => dispatch(fetchProductId('DELTA'))}
       />
      </Grid>
     </Link>
     <Link to='/query'>
      <Grid justify='space-between' container xs={3}>
       <img
        src={brand46}
        style={{ height: '60px', width: '90px' }}
        onClick={() => dispatch(fetchProductId('GOSSEN METRAWATT'))}
       />
      </Grid>
     </Link>
     <Link to='/query'>
      <Grid justify='space-between' container xs={3}>
       <img
        src={brand9}
        style={{ height: '60px', width: '90px' }}
        onClick={() => dispatch(fetchProductId('BURKERT'))}
       />
      </Grid>
     </Link>
     <Link to='/query'>
      <Grid justify='space-between' container xs={3}>
       <img
        src={brand10}
        style={{ height: '60px', width: '90px' }}
        onClick={() => dispatch(fetchProductId('FLOWSERVE'))}
       />
      </Grid>
     </Link>
    </Grid>
    <Grid
     container
     xs={12}
     direction='row'
     justify='space-between'
     alignItems='flex-start'
    >
     <Link to='/query'>
      <Grid justify='space-between' container xs={3}>
       <img
        src={brand50}
        style={{ height: '60px', width: '90px' }}
        onClick={() => dispatch(fetchProductId('STS'))}
       />
      </Grid>
     </Link>
     <Link to='/query'>
      <Grid justify='space-between' container xs={3}>
       <img
        src={brand51}
        style={{ height: '60px', width: '90px' }}
        onClick={() => dispatch(fetchProductId('KITO'))}
       />
      </Grid>
     </Link>
     <Link to='/query'>
      <Grid justify='space-between' container xs={3}>
       <img
        src={brand52}
        style={{ height: '60px', width: '90px' }}
        onClick={() => dispatch(fetchProductId('HAUBER'))}
       />
      </Grid>
     </Link>
     <Link to='/query'>
      <Grid justify='space-between' container xs={3}>
       <img
        src={brand6}
        style={{ height: '60px', width: '90px' }}
        onClick={() => dispatch(fetchProductId('KROHNE'))}
       />
      </Grid>
     </Link>
    </Grid>
    <Grid
     container
     xs={12}
     direction='row'
     justify='space-evenly'
     alignItems='flex-start'
    >
     <Link to='/query'>
      <Grid justify='space-between' container xs={3}>
       <img
        src={brand53}
        style={{ height: '60px', width: '90px' }}
        onClick={() => dispatch(fetchProductId('DONALDSON'))}
       />
      </Grid>
     </Link>
     <Link to='/query'>
      <Grid justify='space-between' container xs={3}>
       <img
        src={brand4}
        style={{ height: '60px', width: '90px' }}
        onClick={() => dispatch(fetchProductId('FAIRCHILD'))}
       />
      </Grid>
     </Link>

     <Link to='/query'>
      <Grid justify='space-between' container xs={3}>
       <img
        src={brand54}
        style={{ height: '60px', width: '90px' }}
        onClick={() => dispatch(fetchProductId('KIRCHNER UND TOCHTER'))}
       />
      </Grid>
     </Link>
     <Link to='/query'>
      <Grid justify='space-between' container xs={3}>
       <img
        src={brand18}
        style={{ height: '60px', width: '90px' }}
        onClick={() => dispatch(fetchProductId('VEGA'))}
       />
      </Grid>
     </Link>
    </Grid>
    <Grid
     container
     xs={12}
     direction='row'
     justify='space-between'
     alignItems='flex-start'
    >
     <Link to='/query'>
      <Grid justify='flex-start' container xs={3}>
       <img
        src={brand19}
        style={{ height: '60px', width: '90px' }}
        onClick={() => dispatch(fetchProductId('KOBOLD'))}
       />
      </Grid>
     </Link>
    </Grid>
   </Grid>
   <Grid container xs={4} direction='column' className='querybox'>
    <Grid container>
     <h1>Multibrand Product Offerings</h1>
    </Grid>
    <Grid container>
     <p>
      At UTL, we strive to be the people you call first, the people you trust
      and have confidence in the people you want as your business partner. Our
      business focuses on providing our clients with a wide variety of renowned
      brands in the industrial automation industry while our committed sales and
      purchasing team is constantly sourcing for the best commercial conditions.
      Multibrand industrial automation products in our inventory and within our
      distribution include flow, level, pressure, temperature instruments etc.
      We act as an extension of your team and help your company be more
      efficient and more profitable.
     </p>
    </Grid>
    <Grid container>
     <TextField
      id='outlined-required'
      label='Company name(required)'
      className='textinput'
      defaultValue=''
      variant='outlined'
      name='companyname'
      value={state.companyname}
      onChange={handleChange}
     />
    </Grid>
    <Grid container>
     <TextField
      id='outlined-required'
      label='Email(required)'
      variant='outlined'
      className='textinput'
      defaultValue=''
      value={state.companyemail}
      onChange={handleChange}
     />
    </Grid>
    <Grid container>
     <TextField
      id='outlined-required'
      label='Phone number(required)'
      variant='outlined'
      name='phone'
      className='textinput'
      defaultValue=''
      value={state.phone}
      onChange={handleChange}
     />
    </Grid>
    <Grid container>
     <TextField
      id='outlined-required'
      label='Message(required)'
      multiline
      rows={7}
      variant='outlined'
      name='message'
      className='textinput'
      defaultValue=''
      value={state.message}
      onChange={handleChange}
     />
    </Grid>
    <Grid container className='brandquarybutton'>
     <Button
      style={{
       height: '40px',
       width: '400px',
       backgroundColor: '#2E86C1',
      }}
      variant='contained'
      color='secondary'
      onClick={query}
     >
      Send
     </Button>
    </Grid>
   </Grid>
  </Grid>
 );
};
