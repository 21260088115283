import React from 'react';

class Clock extends React.Component {
 constructor(props) {
  super(props);
  var days = [
   'Sunday',
   'Monday',
   'Tuesday',
   'Wednesday',
   'Thursday',
   'Friday',
   'Saturday',
  ];
  var dayName = days[new Date().getDay()];

  this.state = {
   time: new Date().toLocaleString(),
   day: dayName,
  };
 }
 componentDidMount() {
  this.intervalID = setInterval(() => this.tick(), 1000);
 }
 componentWillUnmount() {
  clearInterval(this.intervalID);
 }
 tick() {
  this.setState({
   time: new Date().toLocaleString(),
  });
 }
 render() {
  return (
   <p className='App-clock'>
    {this.state.day} {this.state.time}.
   </p>
  );
 }
}

export default Clock;
