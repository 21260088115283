import React, { useState } from 'react';
import { Grid, Card, TextField, Button } from '@material-ui/core';
import map from '../assets/MAP.jpg';
import * as emailjs from 'emailjs-com';
import history from '../widget/history';

export const Contact = () => {
 const [state, setState] = useState({
  companyname: '',
  companyemail: '',
  phone: '',
  message: '',
 });

 function handleChange(evt) {
  const value = evt.target.value;
  setState({
   ...state,
   [evt.target.name]: value,
  });
 }
 function query() {
  state.companyname === '' ||
  state.companyname === '' ||
  state.phone === '' ||
  state.message === ''
   ? window.confirm('please fillup all requirement field')
   : emailjs
      .send(
       'service_y3ckqsh',
       'template_t7uqypk',
       {
        message: state.message,
        company_name: state.companyname,
        company_phone: state.phone,
        company_email: state.companyemail,
       },
       'user_IdQoGXFzJTwNYRXZGw8aI'
      )
      .then(
       function (response) {
        if (
         window.confirm(
          'Your message is successfully send.We will conatact you within 24 hours..'
         )
        ) {
         history.push('./');
        }
       },
       function (error) {
        console.log('FAILED...', error);
       }
      );
 }
 return (
  <Grid alignItems='center' container xs={10} direction='column'>
   <Grid
    alignItems='flex-start'
    container
    xs={10}
    direction='row'
    justify='space-between'
    className='aboutsectioncontect'
   >
    <Grid
     alignItems='flex-start'
     container
     xs={4}
     className='aboutdetailscontainer'
    >
     <Card className='aboutsectionaddessbox'>
      <Grid className='aboutsectionaddessboxheader'>
       <h4>Bangladesh Office:</h4>
      </Grid>

      <Grid className='aboutsectionaddessboxfooter'>
       <p>234, Singapore Market, Access Road, Hajipara, Agrabad, Chittagong.</p>
       <p>Phone:+88031726265</p>
       <h6>+8801571736808</h6>
       <p> Email: sales.utl19@gmail.com</p>
      </Grid>
     </Card>
    </Grid>

    <Grid
     alignItems='flex-start'
     container
     xs={4}
     className='aboutdetailscontainer'
    >
     <Card className='aboutsectionaddessbox1'>
      <Grid className='aboutsectionaddessboxheader'>
       <h4> Germany Office:</h4>
      </Grid>

      <Grid className='aboutsectionaddessboxfooter'>
       <p>Walther-Rathenau-Straße 55 Zimmer Nummer: 6302, 39104, Magdeburg</p>
       <p>Phone: +4917667528478</p>
       <p>E-mail: sales@utl19.com</p>
      </Grid>
     </Card>
    </Grid>
    <Grid className='aboutsectioncontectmap'>
     <Card>
      <img src={map} style={{ height: '100%', width: '100%' }}></img>
     </Card>
    </Grid>
   </Grid>
   <Grid className='contactus' direction='column' container xs={9}>
    <Grid className='contactusheader'>
     <h1>Contact Us</h1>
    </Grid>

    <Grid container xs={12} direction='row'>
     <Grid container>
      <TextField
       id='outlined-required'
       label='Company name(required)'
       className='textinputcontactus'
       defaultValue=''
       variant='outlined'
       name='companyname'
       value={state.companyname}
       onChange={handleChange}
      />
     </Grid>
     <Grid container>
      <TextField
       id='outlined-required'
       label='Email(required)'
       variant='outlined'
       className='textinputcontactus1'
       defaultValue=''
       name='companyemail'
       value={state.companyemail}
       onChange={handleChange}
      />
     </Grid>
     <Grid container>
      <TextField
       id='outlined-required'
       label='Phone number(required)'
       variant='outlined'
       className='textinputcontactus2'
       defaultValue=''
       name='phone'
       value={state.phone}
       onChange={handleChange}
      />
     </Grid>
    </Grid>
    <Grid>
     <Grid container>
      <TextField
       id='outlined-required'
       label='Message(required)'
       multiline
       rows={8}
       name='message'
       variant='outlined'
       className='textinputcontactus3'
       defaultValue=''
       value={state.message}
       onChange={handleChange}
      />
     </Grid>
    </Grid>
    <Grid>
     <Grid container className='textinputcontactubutton'>
      <Button
       style={{
        height: '80px',
        width: '700px',
        backgroundColor: '#2E86C1',
       }}
       variant='contained'
       color='secondary'
       onClick={query}
      >
       Send
      </Button>
     </Grid>
    </Grid>
   </Grid>
  </Grid>
 );
};
