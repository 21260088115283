import { Grid } from '@material-ui/core';
import React from 'react';

export const NoMatch = () => {
 return (
  <Grid container xs={12} direction='column' className='nomatch'>
   <h1>Not Found</h1>
   <p>The requested URL was not found on this server.</p>
   <p>
    Additionally, a 404 Not Found error was encountered while trying to use an
    ErrorDocument to handle the request.
   </p>
  </Grid>
 );
};
