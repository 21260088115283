import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import selectedBrandReducer from './selectedBrandReducer';

const persistConfig = {
 key: 'root',
 storage,
 whitelist: ['selectedProduct'],
};

const allReducer = combineReducers({
 selectedProduct: selectedBrandReducer,
});

export default persistReducer(persistConfig, allReducer);
