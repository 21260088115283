import React, { useState } from 'react';
import { Grid, TextField, Button } from '@material-ui/core';
import { useSelector } from 'react-redux';
import * as emailjs from 'emailjs-com';
import history from '../widget/history';

export const Quary = () => {
 const SelectedId = useSelector((state) =>
  state.selectedProduct.selectedId.toString()
 );
 const [state, setState] = useState({
  companyname: '',
  companyemail: '',
  phone: '',
  message: '',
 });

 function handleChange(evt) {
  const value = evt.target.value;
  setState({
   ...state,
   [evt.target.name]: value,
  });
 }
 function query() {
  state.companyname === '' ||
  state.companyname === '' ||
  state.phone === '' ||
  state.message === ''
   ? window.confirm('please fillup all requirement field')
   : emailjs
      .send(
       'service_zpeq3x3',
       'template_t7uqypk',
       {
        productname: `${SelectedId}`,
        message: state.message,
        company_name: state.companyname,
        company_phone: state.phone,
        company_email: state.companyemail,
       },
       'user_IdQoGXFzJTwNYRXZGw8aI'
      )
      .then(
       function (response) {
        if (
         window.confirm(
          'Your message is successfully send.We will conatact you within 24 hours..'
         )
        ) {
         history.push('./');
        }
       },
       function (error) {
        console.log('FAILED...', error);
       }
      );
 }

 return (
  <Grid
   container
   xs={6}
   direction='column'
   justify='center'
   alignItems='center'
   className='messagequarybox'
  >
   <Grid container>
    <h1>Make an enquiry for {SelectedId} products</h1>
   </Grid>

   <Grid container>
    <TextField
     id='outlined-required'
     label='Company name(required)'
     className='textinput'
     defaultValue=''
     variant='outlined'
     name='companyname'
     value={state.companyname}
     onChange={handleChange}
    />
   </Grid>
   <Grid container>
    <TextField
     id='outlined-required'
     label='Email(required)'
     name='companyemail'
     variant='outlined'
     className='textinput'
     defaultValue=''
     value={state.companyemail}
     onChange={handleChange}
    />
   </Grid>
   <Grid container>
    <TextField
     id='outlined-required'
     label='Phone number(required)'
     variant='outlined'
     name='phone'
     className='textinput'
     defaultValue=''
     value={state.phone}
     onChange={handleChange}
    />
   </Grid>
   <Grid container>
    <TextField
     id='outlined-required'
     label='Message(required)'
     multiline
     rows={7}
     variant='outlined'
     name='message'
     className='textinput'
     defaultValue=''
     value={state.message}
     onChange={handleChange}
    />
   </Grid>
   <Grid className='messagequarybutton' container>
    <Button
     style={{
      height: '40px',
      width: '400px',
      backgroundColor: '#2E86C1',
     }}
     variant='contained'
     color='secondary'
     onClick={query}
    >
     Send
    </Button>
   </Grid>
  </Grid>
 );
};
