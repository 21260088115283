import { Grid } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import ProjectsLoading from '../widget/projectloading';
import Pagination from '../widget/pagination';

import axios from 'axios';

export const Projects = () => {
 const [projects, setprojects] = useState([]);
 const [loading, setLoading] = useState(false);
 const [currentPage, setCurrentPage] = useState(1);
 const [postsPerPage] = useState(4);
 useEffect(() => {
  const fetchprojects = async () => {
   setLoading(true);
   const res = await axios.get('https://utl-backend.herokuapp.com/getprojects');
   setprojects(res.data);
   setLoading(false);
  };
  fetchprojects();
 }, []);
 // Get current posts
 const indexOfLastPost = currentPage * postsPerPage;
 const indexOfFirstPost = indexOfLastPost - postsPerPage;
 const currentprojects = projects.slice(indexOfFirstPost, indexOfLastPost);

 // Change page
 const paginate = (pageNumber) => setCurrentPage(pageNumber);
 return (
  <Grid container xs={9} className='projectlayer'>
   <Grid container xs={9} justify='flex-start' direction='row'>
    <ProjectsLoading projects={currentprojects} loading={loading} />
    <Grid>
     <Pagination
      postsPerPage={postsPerPage}
      totalPosts={projects.length}
      paginate={paginate}
     />
    </Grid>
   </Grid>
  </Grid>
 );
};
