const initialState = {
 selectedbrand: '',
};

const selectedBrandReducer = (state = initialState, action) => {
 switch (action.type) {
  case 'SELECTED_BRAND':
   return {
    ...state,
    selectedId: action.payload,
   };

  default:
   return state;
 }
};

export default selectedBrandReducer;
