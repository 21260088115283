import React from 'react';
import { Grid } from '@material-ui/core';
import '../App.css';

import { Link } from 'react-router-dom';

export const Footer = () => {
 return (
  <Grid
   direction='row'
   justify='space-between'
   alignItems='center'
   container
   xs={11}
   className='footersection'
  >
   <Grid container xs={6}>
    <Grid container xs={12} direction='row' alignItems='center'>
     <Link to='/'>
      <h1>Home</h1>
     </Link>
     &#124;
     <Link to='/products'>
      <h1>Products</h1>
     </Link>
     &#124;
     <Link to='/brand'>
      <h1>Brand</h1>
     </Link>
     &#124;
     <Link to='/projects'>
      <h1>Project</h1>
     </Link>
     &#124;
     <Link to='/logistics'>
      <h1>Logistics</h1>
     </Link>
     &#124;
     <Link to='/contact'>
      <h1>Contact Us</h1>
     </Link>
    </Grid>
   </Grid>
   <Grid container xs={5}>
    <h6>
     Intellectual and other property rights to the information contained in this
     site are held by Universal Trade & Logistics with all rights reserved ©
     2020
    </h6>
   </Grid>
  </Grid>
 );
};
