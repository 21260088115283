import React from 'react';
import { Grid, Card } from '@material-ui/core';
import brand1 from '../assets/Actuator1.png';
import brand2 from '../assets/Actuator2.png';
import brand3 from '../assets/Actuator3.png';
import brand4 from '../assets/Actuator4.png';
import brand5 from '../assets/Actuator5.png';
import brand6 from '../assets/Actuator6.png';
import brand7 from '../assets/Actuator7.png';
import bearing1 from '../assets/Bearing1.png';
import bearing2 from '../assets/Bearing2.png';
import bearing3 from '../assets/Bearing3.png';
import bearing4 from '../assets/Bearing4.png';
import bearing5 from '../assets/Bearing5.png';
import filterelement1 from '../assets/filterelement1.png';
import filterelement2 from '../assets/filterelement2.png';
import filterelement3 from '../assets/filterelement3.png';
import filterelement4 from '../assets/filterelement4.png';
import filterelement5 from '../assets/filterelement5.png';
import relay1 from '../assets/relay1.png';
import relay2 from '../assets/relay2.png';
import relay3 from '../assets/relay3.png';
import circuitbreaker1 from '../assets/circuitbreaker1.png';
import circuitbreaker2 from '../assets/circuitbreaker2.png';
import circuitbreaker3 from '../assets/circuitbreaker3.png';
import circuitbreaker4 from '../assets/circuitbreaker4.png';

import valves1 from '../assets/valves1.png';
import valves2 from '../assets/valves2.png';
import valves3 from '../assets/valves3.png';
import valves4 from '../assets/valves4.png';
import valves5 from '../assets/valves5.png';
import valves6 from '../assets/valves6.png';
import valves7 from '../assets/valves7.png';
import sensor1 from '../assets/sensor1.png';
import sensor2 from '../assets/sensor2.png';
import sensor3 from '../assets/sensor3.png';
import sensor4 from '../assets/sensor4.png';
import sensor5 from '../assets/sensor5.png';
import sensor6 from '../assets/sensor6.png';
import sensor7 from '../assets/sensor7.png';
import sensor8 from '../assets/sensor8.png';
import sensor9 from '../assets/sensor9.png';
import sensor10 from '../assets/sensor10.png';
import sensor11 from '../assets/sensor11.png';
import encoder1 from '../assets/encoder1.png';
import encoder2 from '../assets/encoder2.png';
import encoder3 from '../assets/encoder3.png';
import encoder4 from '../assets/encoder4.png';
import encoder5 from '../assets/encoder5.png';
import encoder6 from '../assets/encoder6.png';
import encoder7 from '../assets/encoder7.png';
import encoder8 from '../assets/encoder8.png';
import encoder9 from '../assets/encoder9.png';
import encoder10 from '../assets/encoder10.png';
import encoder11 from '../assets/encoder11.png';
import pressuretransmitter1 from '../assets/pressuretransmitter1.png';
import pressuretransmitter2 from '../assets/pressuretransmitter2.png';
import pressuretransmitter3 from '../assets/pressuretransmitter3.png';
import pressuretransmitter4 from '../assets/pressuretransmitter4.png';
import pressuretransmitter5 from '../assets/pressuretransmitter5.png';
import pressuretransmitter6 from '../assets/pressuretransmitter6.png';
import pressureswitch1 from '../assets/pressureswitch1.png';
import pressureswitch2 from '../assets/pressureswitch2.png';

import pressureswitch3 from '../assets/pressureswitch3.png';
import pressureswitch4 from '../assets/pressureswitch4.png';
import membrane1 from '../assets/membrane1.png';
import membrane2 from '../assets/membrane2.png';
import membrane3 from '../assets/membrane3.png';
import membrane4 from '../assets/membrane4.png';
import membrane5 from '../assets/membrane5.png';
import membrane6 from '../assets/membrane6.png';
import driveinverter1 from '../assets/driveinverter1.png';
import driveinverter2 from '../assets/driveinverter2.png';
import driveinverter3 from '../assets/driveinverter3.png';
import driveinverter4 from '../assets/driveinverter4.png';
import driveinverter5 from '../assets/driveinverter5.png';
import electricmotor1 from '../assets/electricmotor1.png';
import electricmotor2 from '../assets/electricmotor2.png';
import electricmotor3 from '../assets/electricmotor3.png';
import electricmotor4 from '../assets/electricmotor4.png';
import electricmotor5 from '../assets/electricmotor5.png';
import pump1 from '../assets/pump1.png';
import pump2 from '../assets/pump2.png';
import pump3 from '../assets/pump3.png';

import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.min.css';
import 'owl.carousel/dist/assets/owl.theme.default.min.css';

export const Products = () => {
 return (
  <Grid
   direction='row'
   justify='space-between'
   alignItems='center'
   container
   xs={11}
   className='aboutcontainer'
  >
   <Grid
    alignItems='flex-start'
    container
    xs={10}
    className='aboutdetailscontainer'
   >
    <Card className='productshowscard'>
     <h2>Actuator</h2>

     <Grid container xs={12} className='producttile'>
      <Card className='producttile'>
       <OwlCarousel autoPlay nav items='4' autoplay loop={true} margin={10}>
        <div className='brand'>
         <img src={brand1} style={{ height: '100px', width: '140px' }} />
        </div>
        <div className='brand'>
         <img src={brand2} style={{ height: '100px', width: '140px' }} />
        </div>
        <div className='brand'>
         <img src={brand3} style={{ height: '100px', width: '140px' }} />
        </div>
        <div className='brand'>
         <img src={brand4} style={{ height: '100px', width: '140px' }} />
        </div>
        <div className='brand'>
         <img src={brand5} style={{ height: '100px', width: '140px' }} />
        </div>
        <div className='brand'>
         <img src={brand6} style={{ height: '100px', width: '140px' }} />
        </div>
        <div className='brand'>
         <img src={brand7} style={{ height: '100px', width: '140px' }} />
        </div>
       </OwlCarousel>
      </Card>
     </Grid>
    </Card>

    <Card className='productshowscard1'>
     <h2>Valves</h2>

     <Grid container xs={12}>
      <Card className='producttile'>
       <OwlCarousel autoPlay nav items='4' autoplay loop={true} margin={10}>
        <div className='brand'>
         <img src={valves1} style={{ height: '100px', width: '140px' }} />
        </div>
        <div className='brand'>
         <img src={valves2} style={{ height: '100px', width: '140px' }} />
        </div>
        <div className='brand'>
         <img src={valves3} style={{ height: '100px', width: '140px' }} />
        </div>
        <div className='brand'>
         <img src={valves4} style={{ height: '100px', width: '140px' }} />
        </div>
        <div className='brand'>
         <img src={valves5} style={{ height: '100px', width: '140px' }} />
        </div>
        <div className='brand'>
         <img src={valves6} style={{ height: '100px', width: '140px' }} />
        </div>
        <div className='brand'>
         <img src={valves7} style={{ height: '100px', width: '140px' }} />
        </div>
       </OwlCarousel>
      </Card>
     </Grid>
    </Card>
    <Card className='productshowscard1'>
     <h2>Sensor</h2>

     <Grid container xs={12}>
      <Card className='producttile'>
       <OwlCarousel autoPlay nav items='4' autoplay loop={true} margin={10}>
        <div className='brand'>
         <img src={sensor1} style={{ height: '100px', width: '140px' }} />
        </div>
        <div className='brand'>
         <img src={sensor2} style={{ height: '100px', width: '140px' }} />
        </div>
        <div className='brand'>
         <img src={sensor3} style={{ height: '100px', width: '140px' }} />
        </div>
        <div className='brand'>
         <img src={sensor4} style={{ height: '100px', width: '140px' }} />
        </div>
        <div className='brand'>
         <img src={sensor5} style={{ height: '100px', width: '140px' }} />
        </div>
        <div className='brand'>
         <img src={sensor6} style={{ height: '100px', width: '140px' }} />
        </div>
        <div className='brand'>
         <img src={sensor7} style={{ height: '100px', width: '140px' }} />
        </div>
        <div className='brand'>
         <img src={sensor8} style={{ height: '100px', width: '140px' }} />
        </div>
        <div className='brand'>
         <img src={sensor9} style={{ height: '100px', width: '140px' }} />
        </div>
        <div className='brand'>
         <img src={sensor10} style={{ height: '100px', width: '140px' }} />
        </div>
        <div className='brand'>
         <img src={sensor11} style={{ height: '100px', width: '140px' }} />
        </div>
       </OwlCarousel>
      </Card>
     </Grid>
    </Card>
    <Card className='productshowscard1'>
     <h2>Encoder</h2>

     <Grid container xs={12}>
      <Card className='producttile'>
       <OwlCarousel autoPlay nav items='4' autoplay loop={true} margin={10}>
        <div className='brand'>
         <img src={encoder1} style={{ height: '100px', width: '140px' }} />
        </div>
        <div className='brand'>
         <img src={encoder2} style={{ height: '100px', width: '140px' }} />
        </div>
        <div className='brand'>
         <img src={encoder3} style={{ height: '100px', width: '140px' }} />
        </div>
        <div className='brand'>
         <img src={encoder4} style={{ height: '100px', width: '140px' }} />
        </div>
        <div className='brand'>
         <img src={encoder5} style={{ height: '100px', width: '140px' }} />
        </div>
        <div className='brand'>
         <img src={encoder6} style={{ height: '100px', width: '140px' }} />
        </div>
        <div className='brand'>
         <img src={encoder7} style={{ height: '100px', width: '140px' }} />
        </div>
        <div className='brand'>
         <img src={encoder8} style={{ height: '100px', width: '140px' }} />
        </div>
        <div className='brand'>
         <img src={encoder9} style={{ height: '100px', width: '140px' }} />
        </div>
        <div className='brand'>
         <img src={encoder10} style={{ height: '100px', width: '140px' }} />
        </div>
        <div className='brand'>
         <img src={encoder11} style={{ height: '100px', width: '140px' }} />
        </div>
       </OwlCarousel>
      </Card>
     </Grid>
    </Card>

    <Card className='productshowscard1'>
     <h2>Pressure Transmitter</h2>

     <Grid container xs={12}>
      <Card className='producttile'>
       <OwlCarousel autoPlay nav items='4' autoplay loop={true} margin={10}>
        <div className='brand'>
         <img
          src={pressuretransmitter1}
          style={{ height: '100px', width: '140px' }}
         />
        </div>
        <div className='brand'>
         <img
          src={pressuretransmitter2}
          style={{ height: '100px', width: '140px' }}
         />
        </div>
        <div className='brand'>
         <img
          src={pressuretransmitter3}
          style={{ height: '100px', width: '140px' }}
         />
        </div>
        <div className='brand'>
         <img
          src={pressuretransmitter4}
          style={{ height: '100px', width: '140px' }}
         />
        </div>
        <div className='brand'>
         <img
          src={pressuretransmitter5}
          style={{ height: '100px', width: '140px' }}
         />
        </div>
        <div className='brand'>
         <img
          src={pressuretransmitter6}
          style={{ height: '100px', width: '140px' }}
         />
        </div>
       </OwlCarousel>
      </Card>
     </Grid>
    </Card>
    <Card className='productshowscard1'>
     <h2>Pressure Switch</h2>

     <Grid container xs={12}>
      <Card className='producttile'>
       <OwlCarousel autoPlay nav items='4' autoplay loop={true} margin={10}>
        <div className='brand'>
         <img
          src={pressureswitch1}
          style={{ height: '100px', width: '140px' }}
         />
        </div>
        <div className='brand'>
         <img
          src={pressureswitch2}
          style={{ height: '100px', width: '140px' }}
         />
        </div>
        <div className='brand'>
         <img
          src={pressureswitch3}
          style={{ height: '100px', width: '140px' }}
         />
        </div>
        <div className='brand'>
         <img
          src={pressureswitch4}
          style={{ height: '100px', width: '140px' }}
         />
        </div>
       </OwlCarousel>
      </Card>
     </Grid>
    </Card>
    <Card className='productshowscard1'>
     <h2>Membrane</h2>

     <Grid container xs={12}>
      <Card className='producttile'>
       <OwlCarousel autoPlay nav items='4' autoplay loop={true} margin={10}>
        <div className='brand'>
         <img src={membrane1} style={{ height: '100px', width: '140px' }} />
        </div>
        <div className='brand'>
         <img src={membrane2} style={{ height: '100px', width: '140px' }} />
        </div>
        <div className='brand'>
         <img src={membrane3} style={{ height: '100px', width: '140px' }} />
        </div>
        <div className='brand'>
         <img src={membrane4} style={{ height: '100px', width: '140px' }} />
        </div>
        <div className='brand'>
         <img src={membrane5} style={{ height: '100px', width: '140px' }} />
        </div>
        <div className='brand'>
         <img src={membrane6} style={{ height: '100px', width: '140px' }} />
        </div>
       </OwlCarousel>
      </Card>
     </Grid>
    </Card>
    <Card className='productshowscard1'>
     <h2>Drive & Inverter</h2>

     <Grid container xs={12}>
      <Card className='producttile'>
       <OwlCarousel autoPlay nav items='4' autoplay loop={true} margin={10}>
        <div className='brand'>
         <img
          src={driveinverter1}
          style={{ height: '100px', width: '140px' }}
         />
        </div>
        <div className='brand'>
         <img
          src={driveinverter2}
          style={{ height: '100px', width: '140px' }}
         />
        </div>
        <div className='brand'>
         <img
          src={driveinverter3}
          style={{ height: '100px', width: '140px' }}
         />
        </div>
        <div className='brand'>
         <img
          src={driveinverter4}
          style={{ height: '100px', width: '140px' }}
         />
        </div>
        <div className='brand'>
         <img
          src={driveinverter5}
          style={{ height: '100px', width: '140px' }}
         />
        </div>
       </OwlCarousel>
      </Card>
     </Grid>
    </Card>
    <Card className='productshowscard1'>
     <h2>Electric Motor</h2>

     <Grid container xs={12}>
      <Card className='producttile'>
       <OwlCarousel autoPlay nav items='4' autoplay loop={true} margin={10}>
        <div className='brand'>
         <img
          src={electricmotor1}
          style={{ height: '100px', width: '140px' }}
         />
        </div>
        <div className='brand'>
         <img
          src={electricmotor2}
          style={{ height: '100px', width: '140px' }}
         />
        </div>
        <div className='brand'>
         <img
          src={electricmotor3}
          style={{ height: '100px', width: '140px' }}
         />
        </div>
        <div className='brand'>
         <img
          src={electricmotor4}
          style={{ height: '100px', width: '140px' }}
         />
        </div>
        <div className='brand'>
         <img
          src={electricmotor5}
          style={{ height: '100px', width: '140px' }}
         />
        </div>
       </OwlCarousel>
      </Card>
     </Grid>
    </Card>
    <Card className='productshowscard1'>
     <h2>Pump</h2>

     <Grid container xs={12}>
      <Card className='producttile'>
       <OwlCarousel autoPlay nav items='3' autoplay loop={true} margin={30}>
        <div className='brand'>
         <img src={pump1} style={{ height: '100px', width: '140px' }} />
        </div>
        <div className='brand'>
         <img src={pump2} style={{ height: '100px', width: '140px' }} />
        </div>
        <div className='brand'>
         <img src={pump3} style={{ height: '100px', width: '140px' }} />
        </div>
       </OwlCarousel>
      </Card>
     </Grid>
    </Card>
    <Card className='productshowscard1'>
     <h2>Bearing</h2>

     <Grid container xs={12}>
      <Card className='producttile'>
       <OwlCarousel autoPlay nav items='4' autoplay loop={true} margin={10}>
        <div className='brand'>
         <img src={bearing1} style={{ height: '100px', width: '140px' }} />
        </div>
        <div className='brand'>
         <img src={bearing2} style={{ height: '100px', width: '140px' }} />
        </div>
        <div className='brand'>
         <img src={bearing3} style={{ height: '100px', width: '140px' }} />
        </div>
        <div className='brand'>
         <img src={bearing4} style={{ height: '100px', width: '140px' }} />
        </div>
        <div className='brand'>
         <img src={bearing5} style={{ height: '100px', width: '140px' }} />
        </div>
       </OwlCarousel>
      </Card>
     </Grid>
    </Card>

    <Card className='productshowscard1'>
     <h2>Circuit Breaker</h2>

     <Grid container xs={12}>
      <Card className='producttile'>
       <OwlCarousel autoPlay nav items='4' autoplay loop={true} margin={10}>
        <div className='brand'>
         <img
          src={circuitbreaker1}
          style={{ height: '100px', width: '140px' }}
         />
        </div>
        <div className='brand'>
         <img
          src={circuitbreaker2}
          style={{ height: '100px', width: '140px' }}
         />
        </div>
        <div className='brand'>
         <img
          src={circuitbreaker3}
          style={{ height: '100px', width: '140px' }}
         />
        </div>
        <div className='brand'>
         <img
          src={circuitbreaker4}
          style={{ height: '100px', width: '140px' }}
         />
        </div>
       </OwlCarousel>
      </Card>
     </Grid>
    </Card>
    <Card className='productshowscard1'>
     <h2>Relay</h2>

     <Grid container xs={12}>
      <Card className='producttile'>
       <OwlCarousel autoPlay nav items='3' autoplay loop={true} margin={30}>
        <div className='brand'>
         <img src={relay1} style={{ height: '100px', width: '140px' }} />
        </div>
        <div className='brand'>
         <img src={relay2} style={{ height: '100px', width: '140px' }} />
        </div>
        <div className='brand'>
         <img src={relay3} style={{ height: '100px', width: '140px' }} />
        </div>
       </OwlCarousel>
      </Card>
     </Grid>
    </Card>
    <Card className='productshowscard1'>
     <h2>Filter Element</h2>

     <Grid container xs={12}>
      <Card className='producttile'>
       <OwlCarousel autoPlay nav items='4' autoplay loop={true} margin={10}>
        <div className='brand'>
         <img
          src={filterelement1}
          style={{ height: '100px', width: '140px' }}
         />
        </div>
        <div className='brand'>
         <img
          src={filterelement2}
          style={{ height: '100px', width: '140px' }}
         />
        </div>
        <div className='brand'>
         <img
          src={filterelement3}
          style={{ height: '100px', width: '140px' }}
         />
        </div>
        <div className='brand'>
         <img
          src={filterelement4}
          style={{ height: '100px', width: '140px' }}
         />
        </div>
        <div className='brand'>
         <img
          src={filterelement5}
          style={{ height: '100px', width: '140px' }}
         />
        </div>
       </OwlCarousel>
      </Card>
     </Grid>
    </Card>
   </Grid>
  </Grid>
 );
};
